// This component is to render the sidebar menu on the main page in the left pane
//It shows different menu options, which the user can select to access the relevant page/information
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import Tooltip from '@mui/material/Tooltip';
import {ReactComponent as SupportIcon} from '../Assets/svg/supportIcon.svg';
import {ReactComponent as IncidentIcon} from '../Assets/svg/incidentIcon.svg';
import {ReactComponent as InformationIcon} from '../Assets/svg/informationIcon.svg';
import CustomVersionDropdown from './CustomVersionDropdown';
import {
  HOME,
  TUTORIALS,
  BACKLOG,
} from '../Types/MenuTypes'
import { ReactComponent as RocheLogoBlue } from '../Assets/svg/RocheLogoBlue.svg';

const MenuSideBar = ({ menuState, setMenuState }) => {
  const optionActiveStyle = "border-start border-3 border-primary mb-2 ps-md-4 ps-sm-2 fs-5 w-100"
  const optionDefaultStyle = "border-start border-3 border-white mb-2 ps-md-4 ps-sm-2 fs-5 w-100"

  // Function to handle click on menu options and update the menu state accordingly
  const handleClick = async (option) => {
    switch (option) {
      case HOME:
      case TUTORIALS:
      case BACKLOG:
        setMenuState(option)
        break
      default:
        setMenuState(HOME)
    }
  }

  return (
    <div className="row mt-2 mx-2 h-100">
      <div class="col m-0 p-0 w-100">
        <div className="ps-md-4 ps-sm-2 w-100">
          <RocheLogoBlue className="main-navbar-logo mt-2 mb-5"/>
        </div>

        {/*Menu option - Home*/}
        <div
          className={menuState === HOME ? optionActiveStyle : optionDefaultStyle}
          onClick={() => handleClick(HOME)}
          role="button"
        >
          <GridViewOutlinedIcon className="pb-1" />Home
        </div>


        {/*Menu option - Tutorials*/}
        <div
          className={menuState === TUTORIALS ? optionActiveStyle : optionDefaultStyle}
          onClick={() => handleClick(TUTORIALS)}
          role="button"
        >
          <OndemandVideoOutlinedIcon className="pb-1" />Tutorials
        </div>

        {/*Menu option - Backlog*/}
        <div
          className={menuState === BACKLOG ? optionActiveStyle : optionDefaultStyle}
          onClick={() => handleClick(BACKLOG)}
          role="button"
        >
          <PendingActionsIcon className="pb-1" />Releases
        </div>

        
        <div class="col border-start border-3 border-white mt-4 mb-2 ps-md-4 ps-sm-2 fs-5 w-100">
          <a
            href="https://roche.service-now.com/rose?id=nr_sc_cat_item&sys_id=a74da5b11bbea090f9be99baab4bcb1b"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none', color: '#0B41CD' }}
          >
            <SupportIcon />
            Support Request
          </a>
          
            <Tooltip
              title="Request support if you have needs or wishes for enhancements or changes in DVA."
              placement="top" fontFamily="inherits"
              arrow
            >
              <InformationIcon style={{marginLeft:'4px'}}/>
            </Tooltip>
         
        </div>
        <div class="col border-start border-3 border-white mb-2 ps-md-4 ps-sm-2 fs-5 w-100">
          <a
            href="https://roche.service-now.com/rose?id=nr_sc_cat_item&sys_id=f1bf99f91bb2a090f9be99baab4bcb74"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none', color: '#0B41CD' }}
          >
            <IncidentIcon />
            Raise an incident
          </a>
          
            <Tooltip
              title="Please raise an incident if you see some service interupption in DVA."
              placement="bottom" fontFamily="inherits"
              arrow
            >
              <InformationIcon  style={{marginLeft:'4px'}}/>
            </Tooltip>
          
        </div>
        <div class="col border-start border-3 border-white mb-2 ps-md-4 ps-sm-2 fs-5 w-100">
          <div class="mt-5 mb-4" style={{ width: '200px', height: '100%', border: '1px #C2BAB5 solid' }}></div>
          <CustomVersionDropdown />
        </div>
      </div>
    </div>
  )
}

export default MenuSideBar